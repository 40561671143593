<template>
  <div>
    <b-card border-variant="light" class="custom-card">
      <b-form-row class="pr-5">
        <b-col> NOME DO CERTIFICADO </b-col>
        <b-col>
          <b-form-input
            ref="name"
            v-model.lazy.trim="form.name"
            :state="validateField('name')"
            placeholder="Informe o nome do certificado"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col> DATA INÍCIO </b-col>
        <b-col>
          <date-picker
            datePickerId="dateStarted"
            :key="getKeyDate(form.dateStarted)"
            :dataValue="form.dateStarted"
            @datePickerChangedValue="onChangedDateStarted"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col> DATA FIM </b-col>
        <b-col>
          <date-picker
            datePickerId="dateFinished"
            :key="getKeyDate(form.dateFinished)"
            :dataValue="form.dateFinished"
            @datePickerChangedValue="onChangedDateFinished"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col> TÍTULO </b-col>
        <b-col>
          <div class="custom-div-reload">
            <b-form-input
              ref="title"
              v-model.lazy.trim="form.title"
              :state="validateField('title')"
              placeholder="Informe o título"
            />
            <img
              class="custom-reload mt-1"
              src="@/assets/icons/restore.svg"
              @click="onSetTitleDefault(false)"
            />
          </div>
        </b-col>
      </b-form-row>
      <b-form-row class="custom-certificate-text">
        <b-col class="text-size-mobile" ref="certificateText">
          TEXTO DO CERTIFICADO
          <span id="disabled-wrapper" class="d-inline-block" tabindex="1">
            <img id="PF-1" src="@/assets/icons/information.svg" />
          </span>
          <b-tooltip placement="bottom" key="PF-1" target="PF-1">
            Importante: Considerar no texto sempre a inclusão das tags [nome do
            médico] e [nome do treinamento] para o preenchimento automático
            dessas informações, idem sugestão de texto do certificado.
          </b-tooltip>
        </b-col>
        <b-col class="h-100">
          <div class="custom-div-reload h-100">
            <b-form-textarea
              id="textarea"
              v-model="form.certificateText"
              :state="validateField('certificateText')"
              placeholder="Informe o texto do certificado"
              class="custom-height-area h-100"
              size="lg"
            ></b-form-textarea>
            <img
              class="custom-reload mt-1"
              src="@/assets/icons/restore.svg"
              @click="onSetCertificateTextDefault(false)"
            />
          </div>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col class="">
          <upload-file
            @updateFile="eventToUpdateFile"
            :multiple="false"
            :description="'Adicionar imagem do certificado'"
            :msgErro="'É necessário inserir uma imagem.'"
            :state="validateFieldFile('files')"
            :required="true"
            ref="upload"
            v-model.lazy.trim="form.files"
          >
            <template slot="custon-label">
              <span
                id="tooltip-custon-label"
                class="d-inline-block pl-1"
                tabindex="1"
              >
                <img id="PF-10" src="@/assets/icons/information.svg" />
              </span>
              <b-tooltip placement="bottom" key="PF-10" target="PF-10">
                <p>
                  O arquivo da imagem deve possuir dimensão de 20 cm x 28 cm, no
                  mínimo 300 DPI de resolução, formato de cor RGB e formato de
                  imagem PNG.
                </p>
                <p>
                  Na imagem do certificado devem constar logomarca, nomes e
                  assinaturas. Deve possuir também um espaço reservado para a
                  inclusão do título e texto do certificado.
                </p>
              </b-tooltip>
            </template>
          </upload-file>
        </b-col>
      </b-form-row>
    </b-card>
    <b-row
      class="my-5 d-flex content-between justify-content-between custom-buttons"
    >
      <div>
        <b-button
          variant="outline-primary"
          :disabled="this.loading"
          @click="this.onCancel"
          class="ml-4"
        >
          Cancelar
        </b-button>

        <b-button
          form="alter-form"
          type="button"
          class="ml-4"
          variant="primary"
          id="submitButtonText"
          :disabled="this.loading"
          @click="() => this.onSubmitHandler(false)"
        >
          Salvar e vincular treinamentos
        </b-button>
      </div>
      <b-button
        variant="outline-primary"
        :disabled="this.loading || (form.files && form.files.length == 0)"
        v-b-modal:panel-certificate-preview-modal
        class="ml-4"
      >
        Visualizar
      </b-button>
    </b-row>
    <attention-modal
      modalId="confirm-cancel-certificate-modal"
      title="Atenção!"
      submitButtonText="Sim, salvar primeiro"
      cancelButtonText="Prosseguir sem salvar"
      ref="confirm-cancel-certificate-modal"
      :onAction="this.onActionConfirmCancel"
    >
      <template slot="description">
        <p
          class="p1 mb-3 confirm-text d-flex align-items-center justify-content-center"
        >
          Todas as alterações realizadas serão perdidas com esta ação.
        </p>
        <p
          class="p1 mb-5 confirm-text d-flex align-items-center justify-content-center"
        >
          Deseja salvar as alterações antes de prosseguir?
        </p>
      </template>
    </attention-modal>
    <CertificationPreviewModal
      :files="form.files"
      :certificateText="this.form.certificateText"
      :title="this.form.title"
    />
  </div>
</template>

<script>
import DatePicker from "../../../components/date-picker/DatePicker.vue";
import {
  validateCheckBox,
  validateField,
  requiredFieldsFilled,
  validateFieldFile,
} from "../../../utils/validate/validate.js";
import { inject } from "@vue/composition-api";
import UploadFile from "../../../components/upload-file/UploadFile.vue";
import {
  saveCertificate,
  updateCertificate,
  getCertificate,
} from "../../../services/certificate/certificateManagementService";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import AttentionModal from "../../../components/base-modals/AttentionModal.vue";
import { createToast } from "../../../components/toast/toast";
import CertificationPreviewModal from "../modal/CertificationPreviewModal.vue";

const DEFAULT_FORM = {
  name: "",
  dateStarted: null,
  dateFinished: null,
  title: "",
  certificateText: "",
  files: [],
};

export default {
  name: "general-information-certification",
  components: {
    DatePicker,
    UploadFile,
    AttentionModal,
    CertificationPreviewModal,
  },
  props: ["onChangeTab", "index"],
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      formSubmited: false,
      dateStartedKey: null,
      dateFinishedKey: null,
      loading: false,
      originalValues: DEFAULT_FORM,
      form: DEFAULT_FORM,
      validations: {},
      validateField,
      validateCheckBox,
      requiredFieldsFilled,
      validateFieldFile,
    };
  },
  async mounted() {
    this.globalLoading = false;
    if (this.$route.params.id) {
      const certificate = await getCertificate(this.$route.params.id);

      const response = await fetch(this.getLink(certificate));
      const blob = await response.blob();
      const file = new File([blob], "certificado.png", { type: "image/png" });
      this.form = {
        ...this.form,
        id: this.$route.params.id,
        name: certificate.name,
        dateStarted: certificate.dateInicial,
        dateFinished: certificate.dateFinal,
        title: certificate.title,
        certificateText: certificate.textFile,
        files: [file],
      };
      this.$refs.upload.files = [file];
    }
    this.onSetTitleDefault(true);
    this.onSetCertificateTextDefault(true);
  },
  methods: {
    getLink({ link }) {
      if (this.$store.getters.isApp) {
        return link.replace("https", "http");
      } else {
        return link;
      }
    },
    onSetTitleDefault(validate) {
      const textDefault = "CERTIFICADO";

      if (!validate) {
        this.form.title = textDefault;
        return;
      }

      if (this.form.title == "") {
        this.form.title = textDefault;
      }
    },
    onSetCertificateTextDefault(validate) {
      const textDefault =
        "Certificamos que o Dr(a). [nome do médico] participou do treinamento [nome do treinamento], promovido pela Unimed Grande Florianópolis na modalidade on-line.";

      if (!validate) {
        this.form.certificateText = textDefault;
        return;
      }

      if (this.form.certificateText == "") {
        this.form.certificateText = textDefault;
      }
    },
    onActionConfirmCancel(confirmed) {
      if (!confirmed) {
        this.$router.push(RoutesPaths.certificationManagement.rootName());
        return;
      }
      this.onSubmitHandler(true);
    },
    getKeyDate(date) {
      return date?.toString();
    },
    onChangedDateFinished(value) {
      this.form.dateFinished = value;
    },
    onChangedDateStarted(value) {
      this.form.dateStarted = value;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onCancel() {
      const modal = this.$refs["confirm-cancel-certificate-modal"].$children[0];
      modal.show();
    },
    async onSubmitHandler(cancelButton) {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.loading = true;
        try {
          let response;
          if (this.$route.params.id) {
            response = await updateCertificate({ ...this.form });
          } else {
            response = await saveCertificate({ ...this.form });
          }

          createToast(
            "Informação",
            `Certificado ${
              this.form.id ? "editado" : "cadastrado"
            } com sucesso.`,
            "success",
            2000
          );

          setTimeout(async () => {
            this.loading = false;
            if (cancelButton) {
              return;
            } else {
              if (!this.form.id) {
                await this.$router.push({
                  path: RoutesPaths.certificationManagement.form(response.id),
                });
              }
              this.$props.onChangeTab(this.$props.index + 1);
            }
          }, 1500);
        } catch (error) {
          if (error.response && error.response.status == 400) {
            const msg = `Não é possível ${
              this.form.id ? "alterar" : "salvar"
            } o certificado. Motivo: ${error.response.data.message}`;
            createToast("Informação", msg, "warning");
          } else {
            console.error("onSubmit", error);
            createToast(
              "Atenção",
              "Ocorreu um erro ao salvar informações do treinamento."
            );
          }
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.b-form-tags-button {
  display: none;
}
</style>
<style lang="scss" scoped>
.align-right-all-time {
  position: absolute;
  right: 4px;
}
.b-form-tags-input {
  font-size: 1.2rem;
}
.b-form-tag-content {
  font-size: 1.1rem;
}
.b-form-tag > button.b-form-tag-remove {
  font-size: 180%;
}
.col-tooltip {
  max-width: 0.2rem;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.custom-div-reload {
  display: grid;
  grid-template-columns: auto 24px;
}
.custom-height-area {
  height: 5rem;
}
.custom-reload {
  cursor: pointer;
}
.custom-certificate-text {
  height: 10rem;
}
.custom-buttons {
  padding-right: 1.5rem;
}
</style>
