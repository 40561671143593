<template>
  <div>
    <AlterFormLayout
      modalId="panel-certificate-preview-modal"
      :disableValidation="true"
      :visibleButtons="false"
      :landscape="true"
    >
      <template #content>
        <pdf-viewer
          :landscape="true"
          :getDocumentHandler="getDocumentHandler"
        />
      </template>
    </AlterFormLayout>
  </div>
</template>

<script>
import PdfViewer from "../../../components/pdf-viewer/PdfViewer.vue";
import AlterFormLayout from "../../../layouts/AlterFormLayout.vue";
import { getCertificatePreview } from "../../../services/certificate/certificateManagementService";

export default {
  data() {
    return {
      downloadedFile: null,
      fileName: null,
      fileUrl: null,
    };
  },
  components: {
    AlterFormLayout,
    PdfViewer,
  },
  props: ["files", "certificateText", "title"],
  methods: {
    async getDocumentHandler() {
      const download = await this.downloadFile();
      console.log("download => ", download);
      this.downloadedFile = download.fileBase64;
      const result = atob(download.fileBase64);
      return result;
    },
    async downloadFile() {
      try {
        const response = await getCertificatePreview({
          files: this.$props.files,
          certificateText: this.$props.certificateText,
          title: this.$props.title,
        });
        return response;
      } catch (error) {
        console.error("Error on function downloadFile:", error);
      }
    },
  },
};
</script>
